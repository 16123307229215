<template>
  <div :id="anchorName" class="horizon-owl">
    <h3 v-if="name" class="dynamic-main-title tmw-40-32-15">{{ name }}</h3>
    <v-container class="inner-container">
      <Owl :options="computedOptions" :refreshKey="refreshKey">
        <template v-slot:items>
          <Item v-for="(item, index) in items" :key="index" :item="item" />
        </template>
      </Owl>
    </v-container>
  </div>
</template>

<script>
import dynamicBlock from "@/components/dynamic/dynamicBlock.js";
import Item from "./item.vue";
import owl from "../owl.js";
export default {
  mixins: [owl, dynamicBlock],
  components: {
    Item,
  },
  data: () => ({
    articleImgs: [
      require("@/assets/img/testImg/ImgTest.jpg"),
      require("@/assets/img/testImg/article.png"),
    ],
    refreshKey: 0,
  }),
  computed: {
    autoplay() {
      if (!this.blockData) return false;
      return this.blockData.autoplay;
    },
    autoplayTimeout() {
      if (!this.blockData) return 5000;
      return this.blockData.interval * 1000;
    },
    defaultOptions() {
      return {
        items: 1,
        nav: false,
        dots: true,
        margin: 60,
        animateOut: "fadeOut",
        mouseDrag: false,
        touchDrag: this.items.length > 1,
        autoplayHoverPause: true,
        autoplay: this.autoplay,
        autoplayTimeout: this.autoplayTimeout,
      };
    },
    computedOptions() {
      let computedOptions = {
        ...this.defaultOptions,
        ...this.options,
      };
      return computedOptions;
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler() {
        this.setEachItemSameHeight();
      },
    },
    windowWidth() {
      this.setEachItemSameHeight();
    },
  },
  methods: {
    async setEachItemSameHeight() {
      await this.$nextTick();
      // 每次改變寬度時先復原每個item的高, 等0.5s後再抓最外層的高當基準
      $(this.$el).find(".article-item2").css({
        height: `auto`,
      });

      await this.$helper.delay(0.5);
      if (this.windowWidth >= this.$vuetify.breakpoint.thresholds.md) return;
      const h = $(this.$el).find(".owl-stage").height();
      $(this.$el)
        .find(".article-item2")
        .css({
          height: `${h}px`,
        });
    },
  },
};
</script>